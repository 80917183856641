import { parse, parsePayloads, write } from "sdp-transform";
import { Chart } from "chart.js";

export type MediaFormat = {
  payload: number;
  codec: string;
  rate?: number;
  encoding?: number;
  fmtps: string[];
  type: "audio" | "video";
  toString: () => string;
};

async function getSupportedMediaFormats(
  sdp: RTCSessionDescriptionInit,
  type: "audio" | "video"
): Promise<MediaFormat[]> {
  return parse(sdp.sdp)
    .media.filter((media) => media.type === type)
    .flatMap((media) =>
      media.rtp
        .filter((rtp) => {
          const codec = rtp.codec.toLowerCase();

          // see https://developer.mozilla.org/en-US/docs/Web/Media/Formats/WebRTC_codecs#getting_the_supported_codecs
          return (
            codec !== "rtx" &&
            codec !== "red" &&
            codec !== "fec" &&
            codec !== "ulpfec"
          );
        })
        .map((rtp) => {
          const fmtps = media.fmtp
            .filter((f) => f.payload === rtp.payload)
            .map((f) => f.config);

          const toString = () => {
            return `${rtp.codec} (${rtp.payload}) @ ${rtp.rate} [${fmtps.join(
              " "
            )}]`;
          };

          return { ...rtp, fmtps, type, toString };
        })
    );
}

function forceMediaFormat(
  sdp: RTCSessionDescriptionInit,
  format: MediaFormat
): RTCSessionDescriptionInit {
  const parsed = parse(sdp.sdp);
  parsed.media = parsed.media.map((media) => {
    if (media.type === format.type) {
      media.rtp = media.rtp.filter((f) => f.payload === format.payload);
      media.fmtp = media.fmtp.filter((f) => f.payload === format.payload);
      media.rtcpFb = media.rtcpFb.filter((f) => f.payload === format.payload);
      media.payloads = parsePayloads(media.payloads)
        .filter((p) => p === format.payload)
        .join(" ");
    }

    return media;
  });

  return {
    type: sdp.type,
    sdp: write(parsed),
  };
}

function processForDuration(
  cb: () => void,
  intervalMs: number,
  durationMs: number
) {
  return new Promise<void>((resolve) => {
    let durationRemainingMs = durationMs;
    const interval = setInterval(function processForDurationStep() {
      cb();
      durationRemainingMs -= intervalMs;
      if (durationRemainingMs <= 0) {
        clearInterval(interval);
        resolve();
      }
    }, intervalMs);
  });
}

function waitForConnectionState(
  peer: RTCPeerConnection,
  state: RTCPeerConnectionState,
  timeoutMs: number
) {
  return new Promise<void>((resolve, reject) => {
    let timeout = setTimeout(() => {
      reject(
        new Error(
          `Timed out before connection reached state: '${state}'. Current state: '${peer.connectionState}'.`
        )
      );
    }, timeoutMs);

    if (peer.connectionState === state) {
      clearTimeout(timeout);
      resolve();
    } else {
      const handler = () => {
        if (peer.connectionState === state) {
          clearTimeout(timeout);
          peer.removeEventListener("connectionstatechange", handler);
          resolve();
        }
      };

      peer.addEventListener("connectionstatechange", handler);
    }
  });
}

async function createTest(
  media: MediaStream,
  type: "audio" | "video",
  selector: (
    sdp: RTCSessionDescriptionInit
  ) => Promise<RTCSessionDescriptionInit>
) {
  const from = new RTCPeerConnection({
    iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
  });

  const to = new RTCPeerConnection({
    iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
  });

  from.addEventListener("icecandidate", (ev) => {
    if (ev.candidate) {
      to.addIceCandidate(ev.candidate);
    }
  });

  to.addEventListener("icecandidate", (ev) => {
    if (ev.candidate) {
      from.addIceCandidate(ev.candidate);
    }
  });

  media.getTracks().forEach((track) => {
    if (track.kind === type) {
      from.addTrack(track);
    }
  });

  // create an offer
  const offer = await from.createOffer();
  const selectedOffer = await selector(offer);
  await from.setLocalDescription(selectedOffer);

  // share it with to, creating an answer
  await to.setRemoteDescription(selectedOffer);
  const answer = await to.createAnswer();
  await to.setLocalDescription(answer);

  // share the answer
  await from.setRemoteDescription(answer);

  return {
    from,
    to,
  };
}

function completeTest(test: {
  from: RTCPeerConnection;
  to: RTCPeerConnection;
}) {
  test.from.close();
  test.to.close();
}

type OutboundTrackId = MediaStreamTrack["id"];
type OutboundTrackStat = RTCOutboundRtpStreamStats & {
  timestampInterval: number;
};
type OutboundTrackStats = Record<OutboundTrackId, OutboundTrackStat[]>;
type TestRunId = number;
type TestRunTrackStat = OutboundTrackStats;
type TestRunTrackStats = Record<TestRunId, TestRunTrackStat>;
type TestRunReport = Record<
  TestRunId,
  { format: MediaFormat; stats: TestRunTrackStat }
>;

export async function runTests(
  durationMs: number,
  status: (message: string, total: number, current: number) => void
): Promise<TestRunReport> {
  const isElectron = navigator.userAgent.includes("Electron");

  if (!navigator.mediaDevices.getDisplayMedia || isElectron) {
    navigator.mediaDevices.getDisplayMedia =
      navigator.mediaDevices.getUserMedia;
  }

  const constraints = isElectron
    ? {
        audio: false,
        video: {
          mandatory: {
            chromeMediaSource: "desktop",
            minFrameRate: 30,
            maxFrameRate: 120,
          },
        },
      }
    : {
        audio: true,
        video: {
          // `min: 30` not supported in edge
          frameRate: { max: 120, ideal: 60 },
        },
      };

  return navigator.mediaDevices
    .getDisplayMedia(constraints)
    .then(async function userAllowedMedia(media) {
      // filled during first run
      let detectedMediaFormats: MediaFormat[] = undefined;

      // filled after each run, into a unique element
      let testRunStats: TestRunTrackStats = {};

      // incremented after each run
      let testRunId = 0;

      async function executeTestPass() {
        const test = await createTest(
          media,
          "video",
          async function sdpSelector(sdp) {
            // must be our first run
            if (detectedMediaFormats === undefined) {
              // capture the formats for future runs
              detectedMediaFormats = await getSupportedMediaFormats(
                sdp,
                "video"
              );
              // but return, unmodified
              return sdp;
            }
            // must be an n-th run
            else {
              // get the next desired format
              const desiredFormat = detectedMediaFormats[testRunId];

              // return the modified sdp
              return forceMediaFormat(sdp, desiredFormat);
            }
          }
        );

        await waitForConnectionState(test.from, "connected", 15 * 1000);

        let outboundTracks = test.from
          .getTransceivers()
          .flatMap((t) => [t.sender.track])
          .filter((t) => t);

        const outboundTrackStats: OutboundTrackStats = {};

        outboundTracks.forEach((track) => {
          outboundTrackStats[track.id] = [];
        });

        let ticks = 0;

        await processForDuration(
          function snapshotTrackStatistics() {
            outboundTracks.forEach((track) => {
              test.from.getStats(track).then((report: RTCStatsReport) => {
                report.forEach((entry: RTCStats) => {
                  if (entry.type === "outbound-rtp") {
                    const outboundRtp: RTCOutboundRtpStreamStats =
                      entry as RTCOutboundRtpStreamStats;
                    outboundTrackStats[track.id].push({
                      ...outboundRtp,
                      timestampInterval: ticks,
                    });
                  }
                });
              });
            });

            ticks++;
          },
          1000,
          durationMs
        );

        testRunStats[testRunId] = outboundTrackStats;

        completeTest(test);
        testRunId++;
      }

      status("Running baseline test", 0, 0);

      // first pass is special
      await executeTestPass();

      status(
        `Will be running ${
          detectedMediaFormats.length
        } tests. Media formats:\n${detectedMediaFormats
          .map((d) => d.toString())
          .join("\n")}`,
        detectedMediaFormats.length,
        testRunId
      );

      // n-th runs occur for each detected media format in the first pass
      for (let i = testRunId; i < detectedMediaFormats.length; i++) {
        status(
          `Beginning test ${i + 1} for format: ${detectedMediaFormats[
            i
          ].toString()}`,
          detectedMediaFormats.length,
          testRunId
        );
        await executeTestPass();
      }

      status(
        "Complete",
        detectedMediaFormats.length,
        detectedMediaFormats.length
      );

      // tests done
      return Object.fromEntries(
        Object.entries(testRunStats).map(([k, v]) => [
          k,
          { format: detectedMediaFormats[k], stats: v },
        ])
      );
    });
}

const testDuration = document.querySelector("input");
const statusArea = document.querySelector("textarea");
const statusBar = document.querySelector("progress");

async function onClick() {
  statusArea.value = "";
  statusArea.style.display = "block";
  statusBar.style.display = "block";

  document.querySelector<HTMLAnchorElement>("#download-results")?.remove();

  const results = await runTests(
    testDuration.valueAsNumber,
    function reportStatus(message, total, current) {
      statusArea.value += `\n${message}`;
      statusBar.max = total;
      statusBar.value = current;
      if (statusBar.style.display === "none") {
        statusBar.style.display = "block";
      }
    }
  );

  statusArea.style.display = "none";
  statusBar.style.display = "none";

  const downloadButton = document.createElement("a");
  downloadButton.id = "download-results";
  downloadButton.style.marginTop = "5px";
  downloadButton.innerText = "Download Results";
  var dataStr =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(results));
  downloadButton.setAttribute("href", dataStr);
  downloadButton.setAttribute("download", "webrtc-encoder-results.json");
  document.body.appendChild(downloadButton);
}

// for normal users
document.querySelector("button").addEventListener("click", onClick);

// for headless users
const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop: string) => searchParams.get(prop),
});

if (params["duration"]) {
  testDuration.value = params["duration"];
}

if (params["action"] === "headless") {
  onClick().then(() => {
    document.querySelector<HTMLAnchorElement>("#download-results")?.click();
  });
}
